<template>
    <div id="respostas">
        <div class="tabela-respostas">
            <!--botão para abrir a moda-->
            <div class="col-12 nova-resposta">
                <v-btn class="primary-button" raised @click="createResposta">
                    <i class="fas fa-plus"></i>
                    Cadastrar Resposta
                </v-btn>
            </div>
            <!--tabela que ira conter as respostas de cada empresa-->
            <CustomTable
                v-if="headers != ''"
                :action="'getRespostas'"
                :getter="$store.getters.mensagens"
                :headers="headers"
                :search="true"
                :title="'Respostas'"
                :icone="'fa fa-comments'"
                :pagination="true"
                :filters="filtros"
                ref="tabela"
            >
                <template v-slot:acoes="{ item }">
                    <v-btn class="primary-button" raised small @click="editarResposta(item.uuid)">
                        <i class="fas fa-cog"></i>
                        Editar
                    </v-btn>
                </template>
            </CustomTable>
            <!--modal para o cadastro das respostas-->
            <v-dialog v-model="dialog_respostas" persistente max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Respostas Positivas</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form ref="form_resposta">
                                <div class="row">
                                    <div class="col-12">
                                        <v-select
                                            :rules="[v => !!v || 'Campo Empresa obrigatório']"
                                            :items="$store.getters.companies"
                                            item-text="name"
                                            item-value="uuid"
                                            v-model="respostas.empresa"
                                            label="Empresa"
                                            placeholder="Empresa"
                                            background-color="white"
                                            hide-details
                                            outlined
                                        />
                                    </div>
                                    <div class="col-12">
                                        <v-text-field
                                            v-model="respostas.mensagem"
                                            label="Resposta Positiva"
                                            placeholder="Resposta Positiva"
                                            background-color="white"
                                            hide-details
                                            outlined
                                        />
                                    </div>
                                </div>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="darken-1" text style="text-transform: capitalize; font-size: 16px;" @click="closeResposta">
                            Cancelar
                        </v-btn>
                        <v-btn class="primary-button" small @click="enviarResposta">
                            Salvar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <DialogMensagem :visible="dialog_resposta" :mensagem="resposta" @close="dialog_resposta=false"/>
            <Loader v-if="loading"/>
        </div>
    </div>
</template>
<!--Funções para trazer e encaminhar dados dados das modais e da tabela-->
<script>
	// importa o store
	import store from '@/store'
	// importa o componente de DialogMensagem
	import DialogMensagem from '@/components/DialogMensagem.vue'
	// importa o componente de Loader
	import Loader from '@/components/Loader.vue'
	// importa o componente de CustomTable
	import CustomTable from '@/components/CustomTable.vue'

    // exporta o componente
    export default {
        // nome do componente
        name: 'Repostas',
		// componentes filhos
		components: {
			// componente de DialogMensagem
			DialogMensagem,
			// componente de CustomTable
			CustomTable,
			// componente de Loader
			Loader,
		},
        // dados do componente
        data: () => ({
            // variável para mostrar a modal para editar/criar uma resposta
            dialog_respostas: false,
            // variável para mostrar a modal de informação
            dialog_resposta: false,
            // variável para a mensagem de resposta
            resposta: {},
            // variável para o loading
            loading: false,
            // variável para criar/editar uma resposta
            respostas: {
                resposta_uuid: '',
                empresa: '',
                mensagem: '',
            },
            // variável para o cabeçalho da tabela
            headers: [
                {
                    value: 'message',
                    text: 'Resposta',
                    sortable: true,
                },
                {
                    value: 'companies.name',
                    text: 'Empresa',
                    sortable: true,
                },
                {
                    value: 'acoes',
                    text: 'Ações',
                    sortable: false,
                },
            ],
            // variável para os filtros da tabela
            filtros: {
                perPage: 20,
            },
        }),
        // funções deste componente
        methods: {
            // função para abrir a modal para criar uma resposta
            async createResposta(){
                // abre a modal
                this.dialog_respostas = true
            },
            // função para enviar uma resposta
            async enviarResposta(){
                let resp = {}
                // caso os campos do formulário estejam válidos
                if(this.$refs.form_resposta.validate()){
                    // coloca o componente como loading
                    this.loading = true
                    // coleta os dados da resposta
                    let dados = await {
                        // coleta a empresa da resposta
                        companies: this.respostas.empresa,
                        // coleta a mensagem
                        message: this.respostas.mensagem,
                    }
                    // caso exista uma resposta_uuid
                    if(this.respostas.resposta_uuid){
                        // armazena os dados para realizar a atualização
                        let date_update = await {
                            dados_resposta: dados,
                            uuid: this.respostas.resposta_uuid
                        }
                        // rota para a atualização dos dados da mensagem
                        resp = await store.dispatch('putResposta', date_update)
                    }else{
                        // rota para a criação da mensagem
                        resp = await store.dispatch('postResposta', dados)
                    }
					// caso algo tenha dado errado
					if(resp.status != 200 && resp.status != 201){
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Algo deu errado!'
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await resp.data.message || resp.data.error
						// mostra a mensagem
						this.dialog_resposta = true
					// caso tenha dado tudo certo
					}else{
						// atribui o título da mensagem
						this.resposta.titulo = await 'Mensagem ' + (this.respostas.resposta_uuid ? 'editada!' : 'cadastrada!')
						// atribui o corpo da mensagem
						this.resposta.mensagem = await 'Mensagem ' + (this.respostas.resposta_uuid ? 'editada' : 'cadastrada') + ' com sucesso!'
						// fecha a modal de create/edit resposta
						this.closeResposta()
						// mostra a mensagem
						this.dialog_resposta = true
					}
					// retira o loading do componente
					this.loading = false
					// atualiza a tabela
					this.$refs.tabela.init()
                }
            },
            // função para coletar uma resposta para editar
            async editarResposta(resposta_uuid){
                // coloca o componente como loading
                this.loading = true
                // faz um dispatch para uma action no vuex e pega a mensagem passando o uuid
                var resp = await store.dispatch('getResposta', resposta_uuid)
                // caso o status da resposta seja 200 (deu certo)
                if(resp.status == 200){
                    // atribui os dados vindos do back à variável local
                    this.respostas.resposta_uuid = await resp.data.answers.uuid || ''
                    this.respostas.empresa = await resp.data.answers.company_uuid || ''
                    this.respostas.mensagem = await resp.data.answers.message || ''
                    // mostra a modal de creat/edit respostas
                    this.createResposta()
                }else{
					// atribui o título da mensagem
					this.resposta.titulo = await 'Algo deu errado!'
					// atribui o corpo da mensagem
					this.resposta.mensagem = await resp.data.message || resp.data.error
					// mostra a mensagem
					this.dialog_resposta = true
				}
				// retira o loading do componente
				this.loading = false
            },
            // funçõ que roda quando é fechada a modal de create/edit respostas
            closeResposta(){
                // fecha a modal
                this.dialog_respostas = false
                // limpa os dados locais da resposta
                this.respostas = {
                    resposta_uuid: '',
                    empresa: '',
                    mensagem: '',
                }
            },
        }
    }
</script>
<!--scss da pagina-->
<style lang="scss">
    #respostas{
        display: flex;
        max-height: 100%;
        padding: 24px;
        flex-wrap: wrap;
        .tabela-respostas{
            width: 100%;
            background-color: #fff;
            border-radius: 10px;
            .nova-resposta{
                display: flex;
                justify-content: flex-end;
                padding-bottom: 0;
                button{
                    i, svg{
                        margin-right: 10px;
                    }
                    text-transform: capitalize;
                }
            }
        }
    }
    .v-dialog{
        .headline{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            font-weight: 600;
            color: #11263C;
        }
        .v-card{
            background-color: #f9f9f9;
        }
        .v-card__title{
            span{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                font-weight: 600;
                color: rgba(17, 38, 60, 1);
            }
            svg{
                margin-left: 15px;
                path{
                    fill: rgba(17, 38, 60, 1);
                }
            }
        }
        .v-text-field__details{
            display: none;
        }
        .div-input{
            padding: 5px;
        }
    }
</style>
